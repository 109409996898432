export class apilist{
    login : string = 'signin';
    reset_password : string = 'resetPassword';
    forgot_password : string = 'forgetPassword';
    change_password : string = 'changePassword';
    getProfile : string = 'profile';
    updateProfile : string = 'profile';
    user : string = 'user';
    status : string = 'user/status';
    exportCSV : string = 'user/export/csv';
    interest : string = 'user/interest';
    activity : string = 'activity';
    activityStatus : string = 'activity/status';
    posts : string = 'post';
    getCms : string = 'cms';
    getContactSupport : string = 'contactUs';
    review : string = 'review';
    dashboardData : string = 'dashboard/total';
    graphData : string = 'dashboard/stats';
    reasons : string = 'reasons';
    addReason : string = 'reason';
    allUsers : string ='notification/list';
    sendNotification : string ='notification';
    location : string ='location';
    addQuestion: string ='addQuestion';
    getQuestion: string ='getQuestion';
    deleteQuestion: string ='deleteQuestion';
    updateQuestion: string ='updateQuestion';
    getInterestCategoriesList: string ='getInterestCategoriesList';
    getInterestCategories: string ='getInterestCategories';
  
    // getInterest: string ='getInterest';
    addInterestCategory: string ='addInterestCategory';
    updateInterestCategory: string ='updateInterestCategory';
    deleteCategoryInterest:string ='deleteCategoryInterest'
    getInterest: string ='getInterest';
    addInterest:string = 'addInterest';
    deleteInterest:string ='deleteInterest';
    updateInterest: string = 'updateInterest';
    addTheme:string='addTheme';
    getTheme:String='getTheme';
  
    updateTheme:string='updateTheme';
    deleteTheme:string='deleteTheme';
    getPrompt:String='getPrompt';
    addPrompt:string='addPrompt';
    updatePrompt:string='updatePrompt';
    deletePrompt:string='deletePrompt';
    getPackages:string='getPackages';
    updatePackages :string='updatePackages';
    addPackages:string='addPackages';
    packages:string='packages';
    cms:string='cms';
    getContactUs:string='getContactUs';
    changeContactStatus:string='changeContactStatus';
    reportUser:string='reportUser';

    
}
