import { Routes } from '@angular/router';
import { AuthGuard } from './services/auth/auth.service';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { TermsconditionsComponent } from './termsconditions/termsconditions.component';

export const AppRoutes: Routes = [

  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'auth',
    loadChildren: './modules/auth/auth.module#AuthModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'privacypolicy',
    component: PrivacypolicyComponent
  
  },
  {
    path: 'termsconditions',
    component: TermsconditionsComponent
  
  },
 
  {
    path: '',
    loadChildren: () => import("./erp/erp.module").then(m => m.ErpModule),
   canActivate:[AuthGuard]
  },
  {
    path: '**',
    redirectTo: 'auth'
  }
]
