import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CommonService } from 'app/services/common/common.service';
import { HttpService } from 'app/services/http/http.service';

@Component({
  selector: 'app-privacypolicy',
  templateUrl: './privacypolicy.component.html',
  styleUrls: ['./privacypolicy.component.css']
})
export class PrivacypolicyComponent implements OnInit {
  Data: any;

  constructor(private http:HttpService, private fb:FormBuilder,public cm: CommonService) { }

  ngOnInit(): void {
    this.getcms()
  }
  getcms(){
    this.http.httpGetWithHeader('cms',false).subscribe((res:any) => {
      this.Data=res.data.privacyPolicy;
   
    

  })

  }

}
